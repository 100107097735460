<template>
  <span
    v-if="
      profile.role != 'User' ||
      (userSession &&
        userSession.displayParameters &&
        userSession.displayParameters.graphs)
    "
  >
    <v-card>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Graphs</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4" v-show="profile.role != 'User'">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="selectedSession != null"
            v-show="profile.role != 'User'"
          >
            <v-select
              :items="teams"
              item-text="name"
              return-object
              label="Sélectionnez une équipe"
              v-model="selectedTeam"
              @change="OnSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <span v-if="selectedTeam && currentGraphs">
          <v-tabs v-model="currentTab" color="primary" centered>
            <v-tab
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphFinancial
              "
            >
              <span>Données financières</span>
            </v-tab>
            <v-tab
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphRentability
              "
            >
              <span
                >Rentabilité
                <v-tooltip bottom max-width="600" color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                      <v-icon small>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-text="
                      'Rentabilité de l\'activité = Resultat net / Chiffre d\'affaires'
                    "
                  ></span>
                  <br />
                  <span
                    v-text="
                      'Rentabilité des capitaux = Situation nette / Capital social'
                    "
                  ></span>
                </v-tooltip>
              </span>
            </v-tab>
            <v-tab
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphNetSituation
              "
            >
              <span>Situation nette</span>
            </v-tab>
            <v-tab
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphDebt
              "
            >
              <span
                >Endettement
                <v-tooltip bottom max-width="600" color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="accent" icon small v-bind="attrs" v-on="on">
                      <v-icon small>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-text="
                      'Taux d\'endettement = (Emprunts + Découvert) / Situation nette'
                    "
                  ></span>
                </v-tooltip>
              </span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab">
            <v-tab-item
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphFinancial
              "
            >
              <div v-if="resultIsVisible || profile.role != 'User'">
                <VueApexCharts
                  ref="financialChart"
                  :options="financialChartOptions"
                  :series="financialChartSeries"
                ></VueApexCharts>
              </div>
              <div
                v-else
                class="mt-5 d-flex flex-row align-center justify-center"
              >
                <h1 class="my-5 accent--text texxt-center">
                  Aucune donnée disponible pour le moment
                </h1>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphRentability
              "
            >
              <div v-if="resultIsVisible || profile.role != 'User'">
                <VueApexCharts
                  ref="rentabilityChart"
                  :options="rentabilityChartOptions"
                  :series="rentabilityChartSeries"
                ></VueApexCharts>
              </div>
              <div
                v-else
                class="mt-5 d-flex flex-row align-center justify-center"
              >
                <h1 class="my-5 accent--text texxt-center">
                  Aucune donnée disponible pour le moment
                </h1>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphNetSituation
              "
            >
              <div v-if="resultIsVisible || profile.role != 'User'">
                <VueApexCharts
                  ref="situationChart"
                  :options="situationChartOptions"
                  :series="situationChartSeries"
                ></VueApexCharts>
              </div>
              <div
                v-else
                class="mt-5 d-flex flex-row align-center justify-center"
              >
                <h1 class="my-5 accent--text texxt-center">
                  Aucune donnée disponible pour le moment
                </h1>
              </div>
            </v-tab-item>
            <v-tab-item
              v-if="
                profile.role != 'User' ||
                userSession.displayParameters.graphDebt
              "
            >
              <div v-if="resultIsVisible || profile.role != 'User'">
                <VueApexCharts
                  ref="debtsChart"
                  :options="debtsChartOptions"
                  :series="debtsChartSeries"
                ></VueApexCharts>
              </div>
              <div
                v-else
                class="mt-5 d-flex flex-row align-center justify-center"
              >
                <h1 class="my-5 accent--text texxt-center">
                  Aucune donnée disponible pour le moment
                </h1>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import Graph from "@/classes/Graph";
import Result from "@/classes/Result";

import dataFormater from "@/assets/functions/dataFormater.js";

import VueApexCharts from "vue-apexcharts";
export default {
  name: "Graphs",
  props: ["user", "profile", "userSession"],
  components: {
    VueApexCharts,
  },
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      teams: [],
      periods: [],

      selectedSession: null,
      selectedTeam: null,

      currentTab: null,

      currentGraphs: null,

      resultIsVisible: false,

      financialChartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#2D75BA", "#F37DAD", "#257659"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 5,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return dataFormater.formatMoney(Math.round(val * 100) / 100);
            },
          },
        },
        responsive: [
          {
            breakpoint: Infinity,
            options: {
              chart: {
                height: "500",
              },
            },
          },
        ],
      },

      rentabilityChartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CC3D0", "#257659"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 5,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return dataFormater.formatPercentage(Math.round(val * 100) / 100);
            },
          },
        },
        responsive: [
          {
            breakpoint: Infinity,
            options: {
              chart: {
                height: "500",
              },
            },
          },
        ],
      },

      situationChartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#F79F1F"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 5,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return dataFormater.formatMoney(Math.round(val * 100) / 100);
            },
          },
        },
        responsive: [
          {
            breakpoint: Infinity,
            options: {
              chart: {
                height: "500",
              },
            },
          },
        ],
      },
      debtsChartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#E45D2B"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 5,
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return dataFormater.formatPercentage(Math.round(val * 100) / 100);
            },
          },
        },
        responsive: [
          {
            breakpoint: Infinity,
            options: {
              chart: {
                height: "500",
              },
            },
          },
        ],
      },

      financialChartSeries: [],
      rentabilityChartSeries: [],
      situationChartSeries: [],
      debtsChartSeries: [],
    };
  },
  async created() {
    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      this.selectedTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
          this.loadGraphs();
        })
      );
    } else {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
        })
      );
    }
  },
  watch: {
    periods: {
      handler: function (val) {
        let periodCategories = [];

        for (let period of this.periods) {
          if (period.end < new Date().getTime()) {
            periodCategories.push(period.name);
          }
        }

        this.financialChartOptions.xaxis.categories = periodCategories;
        this.rentabilityChartOptions.xaxis.categories = periodCategories;
        this.situationChartOptions.xaxis.categories = periodCategories;
        this.debtsChartOptions.xaxis.categories = periodCategories;

        if (this.$refs.financialChart != undefined) {
          this.$refs.financialChart.updateOptions(this.financialChartOptions);
        }
        if (this.$refs.rentabilityChart != undefined) {
          this.$refs.rentabilityChart.updateOptions(
            this.rentabilityChartOptions
          );
        }
        if (this.$refs.situationChart != undefined) {
          this.$refs.situationChart.updateOptions(this.situationChartOptions);
        }
        if (this.$refs.debtsChart != undefined) {
          this.$refs.debtsChart.updateOptions(this.debtsChartOptions);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async OnSessionSelected() {
      let sessionUniverses = await Univers.getBySession(
        this.selectedSession.id
      );
      let tmp_teams = [];
      this.teams = [];

      this.selectedTeam = null;

      this.currentGraphs = null;

      sessionUniverses.forEach((univers) => {
        this.unsub.push(
          Team.listenByUnivers(univers.id, (teams) => {
            teams.forEach((team) => {
              tmp_teams.push(team);
            });
            tmp_teams.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.teams = tmp_teams;
          })
        );
      });
      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods;
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );

      this.OnSelection();
    },
    async OnSelection() {
      if (this.selectedTeam != null) {
        await this.loadGraphs();
      }
    },
    async loadGraphs() {
      this.unsub.push(
        Graph.listenBySession(this.selectedSession.id, async (graphs) => {
          this.currentGraphs = graphs[0];

          let financialInfo = {
            result: [],
            turnover: [],
            treasury: [],
          };
          let rentabilityInfo = {
            activity: [],
            capital: [],
          };
          let situationInfo = [];
          let debtsInfo = [];

          let categories = [];

          let tmp_resultIsVisible = false;
          for (let period of this.periods) {
            let result = await Result.getByPeriodAndTeam(
              period.id,
              this.selectedTeam.id
            );
            if (
              this.profile.role == "User" &&
              result != null &&
              result.length > 0
            ) {
              for (let res of result) {
                if (res.isVisible) {
                  tmp_resultIsVisible = true;
                }
              }
              this.resultIsVisible = tmp_resultIsVisible;
            } else if (this.profile.role != "User") {
              this.resultIsVisible = true;
            }
            if (
              (result != null && result.length > 0 && result[0].isVisible) ||
              this.profile.role != "User"
            ) {
              if (
                this.currentGraphs.financialGraph[this.selectedTeam.univers] !=
                  undefined &&
                this.currentGraphs.financialGraph[this.selectedTeam.univers][
                  this.selectedTeam.id
                ] != undefined &&
                this.currentGraphs.financialGraph[this.selectedTeam.univers][
                  this.selectedTeam.id
                ].result[period.id] != undefined
              ) {
                financialInfo.result.push(
                  this.currentGraphs.financialGraph[this.selectedTeam.univers][
                    this.selectedTeam.id
                  ].result[period.id]
                );
                financialInfo.turnover.push(
                  this.currentGraphs.financialGraph[this.selectedTeam.univers][
                    this.selectedTeam.id
                  ].turnover[period.id]
                );
                financialInfo.treasury.push(
                  this.currentGraphs.financialGraph[this.selectedTeam.univers][
                    this.selectedTeam.id
                  ].treasury[period.id]
                );
                rentabilityInfo.activity.push(
                  this.currentGraphs.rentabilityGraph[
                    this.selectedTeam.univers
                  ][this.selectedTeam.id].activity[period.id]
                );
                rentabilityInfo.capital.push(
                  this.currentGraphs.rentabilityGraph[
                    this.selectedTeam.univers
                  ][this.selectedTeam.id].capital[period.id]
                );
                situationInfo.push(
                  this.currentGraphs.netSituationGraph[
                    this.selectedTeam.univers
                  ][this.selectedTeam.id][period.id]
                );
                debtsInfo.push(
                  this.currentGraphs.debtsGraph[this.selectedTeam.univers][
                    this.selectedTeam.id
                  ][period.id]
                );

                categories.push(period.name);
              }
            }
          }

          let financialData = [];
          financialData.push({
            name: "Resultat net",
            data: financialInfo.result,
          });
          financialData.push({
            name: "Chiffre d'affaires",
            data: financialInfo.turnover,
          });
          financialData.push({
            name: "Trésorerie",
            data: financialInfo.treasury,
          });
          this.financialChartSeries = financialData;

          let rentabilityData = [];
          rentabilityData.push({
            name: "Rentabilité de l'activité",
            data: rentabilityInfo.activity,
          });
          rentabilityData.push({
            name: "Rentabilité des capitaux",
            data: rentabilityInfo.capital,
          });
          this.rentabilityChartSeries = rentabilityData;

          let situationData = [];
          situationData.push({
            name: "Situation nette",
            data: situationInfo,
          });
          this.situationChartSeries = situationData;

          let debtsData = [];
          debtsData.push({
            name: "Endettement",
            data: debtsInfo,
          });
          this.debtsChartSeries = debtsData;

          this.financialChartOptions.xaxis.categories = categories;
          this.rentabilityChartOptions.xaxis.categories = categories;
          this.situationChartOptions.xaxis.categories = categories;
          this.debtsChartOptions.xaxis.categories = categories;

          if (this.$refs.financialChart != undefined) {
            this.$refs.financialChart.updateOptions(this.financialChartOptions);
            this.$refs.financialChart.updateSeries(this.financialChartSeries);
          }
          if (this.$refs.rentabilityChart != undefined) {
            this.$refs.rentabilityChart.updateOptions(
              this.rentabilityChartOptions
            );
            this.$refs.rentabilityChart.updateSeries(
              this.rentabilityChartSeries
            );
          }
          if (this.$refs.situationChart != undefined) {
            this.$refs.situationChart.updateOptions(this.situationChartOptions);
            this.$refs.situationChart.updateSeries(this.situationChartSeries);
          }
          if (this.$refs.debtsChart != undefined) {
            this.$refs.debtsChart.updateOptions(this.debtsChartOptions);
            this.$refs.debtsChart.updateSeries(this.debtsChartSeries);
          }

          this.$forceUpdate();
        })
      );
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
