import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "results"

class Result{
    constructor(id, period, team, bilan, resultAccount, treasuryAccount, salesTable, productionTable, isVisible){
        this.id = id
        this.period = period
        this.team = team
        this.bilan = bilan
        this.resultAccount = resultAccount
        this.treasuryAccount = treasuryAccount
        this.salesTable = salesTable
        this.productionTable = productionTable
        this.isVisible = isVisible
    }

    static async initializeOne(periodId, teamID){
        let tmp_bilan = {
            actif: {
                rawCorporealImmo : 0,
                cumulatedAmortisation : 0,
                netCorporealImmo : 0,
                stockValue : 0,
                customerDebts : 0,
                circulatingActif : 0,
                disponibility : 0,
                total : 0,
            },
            passif: {
                socialCapital : 0,
                reserves : 0,
                result : 0,
                netSituation : 0,
                loans : 0,
                permanentCapitals : 0,
                supplierDebts : 0,
                circulatingPassif : 0,
                overdraft : 0,
                total : 0,
            },
            stat: {
                fr: 0,
                bfr: 0,
            }
        }

        let tmp_resultAccount = {
            exploitation: {
                products:{
                    turnover: 0,
                    productsSalesValue: 0,
                    servicesSalesValue: 0,
                    marketplaceSalesReceived: 0,
                    stockVariation: 0,
                    subventions: 0,
                    otherProducts: [],
                    total: 0,
                },
                charges:{
                    rawMaterials: 0,
                    materialCosts: 0,
                    qualityBudget: 0,
                    marketplaceSalesPaid: 0,
                    externalCharges: 0,
                    structuralCosts: 0,
                    machineStructuralCosts: 0,
                    storageCosts: 0,
                    distributionCosts: 0,
                    communicationBudget: 0,
                    marketingBudget: 0,
                    maintenanceBudget: 0,
                    rndCosts: 0,
                    studies: 0,
                    staffCosts: 0,
                    workerCosts: 0,
                    employeeCosts: 0,
                    productManagerCosts: 0,
                    serviceManagerCosts: 0,
                    salesmanCosts: 0,
                    commissions: 0,
                    trainingCosts: 0,
                    socialBudget: 0,
                    hiringFiringCosts: 0,
                    amortisation: 0,
                    corporealAmortisation: 0,
                    otherCharges: [],
                    total: 0,
                },
                result: 0,
            },
            financial: {
                products:{
                    marketplacePenalityReceived: 0,
                    treasuryPlacements : 0,
                    otherProducts: [],
                    total: 0,
                },
                charges:{
                    marketplacePenalityPaid: 0,
                    loansIntrests: 0,
                    loansPenality: 0,
                    overdraftInterests: 0,
                    discountInterests: 0,
                    otherCharges: [],
                    total: 0,
                },
                result: 0,
            },
            exceptional: {
                products:{
                    machineSalesValue: 0,
                    otherProducts: [],
                    total: 0,
                },
                charges:{
                    CorporealImmoSalesValue: 0,
                    otherCharges: [],
                    total: 0,
                },
                result: 0,
            },
            totalBeforeTax: 0,
            TaxCreditAmount: 0,
            taxBeforeCredit: 0,
            taxAfterCredit: 0,
            newTaxCreditAmount: 0,
            netTotal: 0,
        }
        
        let tmp_treasuryAccount = {
            inputs: {
                capitalIncrease: 0,
                loanReceived : 0,
                subventions : 0,
                machineSales: 0,
                treasuryPlacements: 0,
                customerDebtsReceived: 0,
                salesReceived: 0,
                marketplaceSalesReceived: 0,
                marketplacePenalityReceived: 0,
                discountReceived: 0,
                otherExploitationProducts: [],
                otherFinancialProducts: [],
                otherExceptionalProducts: [],
                total: 0,
            },
            outputs: {
                dividendsPaid: 0,
                loanPaid: 0,
                machinePurchased: 0,
                structuralCosts: 0,
                machineStructuralCosts: 0,
                distributionStorageCosts: 0,
                communicationBudget: 0,
                marketingBudget: 0,
                qualityBudget: 0,
                maintenanceBudget: 0,
                rndCosts: 0,
                studies: 0,
                workerCosts: 0,
                employeeCosts: 0,
                productManagerCosts: 0,
                serviceManagerCosts: 0,
                salesmanCosts: 0,
                commissions: 0,
                trainingCosts: 0,
                socialBudget: 0,
                hiringFiringCosts: 0,
                supplierDebtsPaid: 0,
                rawMaterialsPaid: 0,
                marketplaceSalesPaid: 0,
                marketplacePenalityPaid: 0,
                financialCharges: 0,
                tax: 0,
                otherExploitationCharges: [],
                otherFinancialCharges: [],
                otherExceptionalCharges: [],
                total: 0,
            },
            previousTreasury: 0,
            treasuryVariation: 0,
            treasury: 0,
        }

        let tmp_salesTable = {}

        let tmp_productionTable = {}

        let tmp_result = new Result(null, periodId, teamID, tmp_bilan, tmp_resultAccount, tmp_treasuryAccount, tmp_salesTable, tmp_productionTable, false)
        return tmp_result
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let results = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_result = new Result(doument.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            results.push(tmp_result)
        })

        return results
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let result = new Result(response.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)

        return result
    }

    static async getByPeriod(periodId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId))
        let response = await getDocs(documentQuery)

        let results = []

        response.forEach(document => {
            let data = document.data()
            let tmp_result = new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            results.push(tmp_result)
        })

        return results
    } 

    static async getByTeam(teamId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("team", "==", teamId))
        let response = await getDocs(documentQuery)

        let results = []

        response.forEach(document => {
            let data = document.data()
            let tmp_result = new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            results.push(tmp_result)
        })

        return results
    } 

    static async getByPeriodAndTeam(periodId, teamId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("period", "==", periodId), where("team", "==", teamId))
        let response = await getDocs(documentQuery)

        let results = []

        response.forEach(document => {
            let data = document.data()
            let tmp_result = new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            results.push(tmp_result)
        })

        return results
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let results = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_result = new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
                results.push(tmp_result)
            })
            
            if(callback != null){
                callback(results)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let result = new Result(snapshot.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            
            if(callback != null){
                callback(result)
            }
        })
        return unsub
    }

    static async listenByPeriod(periodId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("period", "==", periodId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let results = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_result =  new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
                results.push(tmp_result)
            })
            
            if(callback != null){
                callback(results)
            }
        })
        return unsub
    } 

    static async listenByTeam(teamId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("team", "==", teamId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let results = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_result =  new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
                results.push(tmp_result)
            })
            
            if(callback != null){
                callback(results)
            }
        })
        return unsub
    } 

    static async listenByPeriodAndTeam(periodId, teamId, callback = null){
      let collectionRef = collection(db, collectionName)
		  let documentsQuery = query(collectionRef, where("period", "==", periodId), where("team", "==", teamId))
        
      let unsub = onSnapshot(documentsQuery, snapshot => {
        let results = []

        snapshot.forEach(document => {
            let data = document.data()
            let tmp_result =  new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
            results.push(tmp_result)
        })
        
        if(callback != null){
            callback(results)
        }
      })
      return unsub
    } 

    static async updateVisibilityByPeriod(periodId, isVisible){
      let collectionRef = collection(db, collectionName)
      let documentQuery = query(collectionRef, where("period", "==", periodId))
      let response = await getDocs(documentQuery)

      response.forEach(async document => {
          let data = document.data()
          let tmp_result = new Result(document.id, data.period, data.team, data.bilan, data.resultAccount, data.treasuryAccount, data.salesTable, data.productionTable, data.isVisible)
          tmp_result.isVisible = isVisible
          await tmp_result.save()
      })
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                period : this.period,
                team : this.team,
                bilan : this.bilan,
                resultAccount : this.resultAccount,
                treasuryAccount : this.treasuryAccount,
                salesTable : this.salesTable,
                productionTable : this.productionTable,
                isVisible : this.isVisible
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                period: this.period,
                team: this.team,
                bilan : this.bilan,
                resultAccount : this.resultAccount,
                treasuryAccount : this.treasuryAccount,
                salesTable : this.salesTable,
                productionTable : this.productionTable,
                isVisible : this.isVisible
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Result