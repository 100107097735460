import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "graphs"

class Graph{
    constructor(id, session, financialGraph, rentabilityGraph, netSituationGraph, debtsGraph){
        this.id = id
        this.session = session
        this.financialGraph = financialGraph
        this.rentabilityGraph = rentabilityGraph
        this.netSituationGraph = netSituationGraph
        this.debtsGraph = debtsGraph
    }

    static async initializeOne(sessionId){
        let tmp_financialGraph = {}
        let tmp_rentabilityGraph = {}
        let tmp_netSituationGraph = {}
        let tmp_debtsGraph = {}

        let tmp_graph = new Graph(null, sessionId, tmp_financialGraph, tmp_rentabilityGraph, tmp_netSituationGraph, tmp_debtsGraph)
        return tmp_graph
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let graph = []

        response.forEach(doument => {
            let data = doument.data()
            let tmp_graph = new Graph(doument.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)
            graph.push(tmp_graph)
        })

        return graph
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let research = new Graph(response.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)

        return research
    }

    static async getBySession(sessionId){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where("session", "==", sessionId))
        let response = await getDocs(documentQuery)

        let graph = []

        response.forEach(document => {
            let data = document.data()
            let tmp_graph = new Graph(document.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)
            graph.push(tmp_graph)
        })

        return graph
    } 
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let graph = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_graph = new Graph(document.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)
                graph.push(tmp_graph)
            })
            
            if(callback != null){
                callback(graph)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let research = new Graph(snapshot.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)
            
            if(callback != null){
                callback(research)
            }
        })
        return unsub
    }

    static async listenBySession(sessionId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("session", "==", sessionId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let graph = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_graph =  new Graph(document.id, data.session, data.financialGraph, data.rentabilityGraph, data.netSituationGraph, data.debtsGraph)
                graph.push(tmp_graph)
            })
            
            if(callback != null){
                callback(graph)
            }
        })
        return unsub
    } 

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                session : this.session,
                financialGraph : this.financialGraph,
                rentabilityGraph : this.rentabilityGraph,
                netSituationGraph : this.netSituationGraph,
                debtsGraph : this.debtsGraph,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                session : this.session,
                financialGraph : this.financialGraph,
                rentabilityGraph : this.rentabilityGraph,
                netSituationGraph : this.netSituationGraph,
                debtsGraph : this.debtsGraph,
            })
        }
        return this
    }

    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Graph